<template>
  <div class="page-container" v-loading="loading">
    <!-- header -->
    <el-card>
      <el-row class="header-search-bar">
        <el-col :span="6">
          <el-input
            placeholder="请输入关键字搜索"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getTeacherList('search')"
            @keyup.enter.native="getTeacherList('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getTeacherList('search')"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <header-select
            :selectItem="['campus']"
            :selectItemAll="['campus']"
            @search="selectSearchHandle"
          ></header-select>
        </el-col>
        <el-col class="header-button" :span="24">
          <upload-excel
            import-btn-type="purple"
            :loading.sync="loading"
            :api="$http.importExcelHttp.importTeacher"
            @on-download="$http.exportExcelHttp.exportTeacherTemplate()"
            @on-success="renderTable" />
          <el-button type="primary" size="mini" @click="createMajor('create')"
          >添加教师
          </el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="teacherList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50"></el-table-column>
        <el-table-column prop="teacherName" label="教师姓名" width="150">
        </el-table-column>
        <el-table-column label="教师类型" width="100">
          <template v-slot="scoped">
            <span v-for="(item, index) in teacherType" :key="index">
              <span v-if="Number(scoped.row.teacherType) === Number(item.paramValue)">{{ item.paramName }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="100">
          <template v-slot="scoped">
            <span v-if="Number(scoped.row.sexType) === 1">男</span>
            <span v-if="Number(scoped.row.sexType) === 2">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="150">
        </el-table-column>
        <!--        <el-table-column prop="deptName" label="所属部门" width="150">-->
        <!--        </el-table-column>-->
        <el-table-column prop="campusName" label="所在校区" width="100">
        </el-table-column>
        <el-table-column label="扫码功能" width="200">
          <template v-slot="scoped">
            <span v-for="(item, index) in scoped.row.functions" :key="index">
              <el-tag style="margin-right: 5px" v-if="Number(item) === 1">报到</el-tag>
              <el-tag style="margin-right: 5px" v-if="Number(item) === 2">校服</el-tag>
              <el-tag style="margin-right: 5px" v-if="Number(item) === 3">校园卡</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注"></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template v-slot="scoped">
            <el-button
              type="warning"
              size="mini"
              @click="createMajor('edit', scoped.row.id)"
            >编辑
            </el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteUserHandle(scoped.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
    <add-teacher :addData="addData" @successHandle="successHandle"></add-teacher>
  </div>
</template>

<script>
import { getTeacherList, deleteTeacher } from '@/api/teacher'
import addTeacher from './dialog/addTeacher.vue'

export default {
  components: {
    addTeacher
  },
  data () {
    return {
      loading: false,
      addData: {
        id: '',
        title: '',
        type: '',
        isOpen: false
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        campusId: null,
        collegeId: null
      },
      teacherList: [],
      teacherType: this.$store.state.systemParam.teacherType,
      total: 0
    }
  },
  methods: {
    // 头部下拉事件
    selectSearchHandle (data) {
      this.queryInfo.campusId = data.campusId
      this.queryInfo.collegeId = data.collegeId
      this.getTeacherList()
    },
    // 导入教师信息
    importTeacher (file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file.raw)
      this.$http.importExcelHttp.importTeacher(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.$message({
            type: 'success',
            message: res.data.msg || '导入成功'
          })
          this.getTeacherList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg || '导入失败，请重新导入'
          })
        }
      })
    },
    // 获取教师列表
    getTeacherList (type) {
      if (type === 'search') {
        this.queryInfo.pageNum = 1
      }
      getTeacherList(this.queryInfo).then((res) => {
        this.teacherList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加与编辑教师
    createMajor (type, id) {
      this.addData.isOpen = true
      this.addData.type = type
      if (type === 'create') {
        this.addData.id = ''
        this.addData.title = '添加教师'
      } else {
        this.addData.id = id
        this.addData.title = '编辑教师'
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getTeacherList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getTeacherList()
    },
    // 删除教师
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该教师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTeacher(id).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getTeacherList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getTeacherList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
